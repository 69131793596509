.titillium-web-extralight {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.titillium-web-light {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.titillium-web-regular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.titillium-web-semibold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.titillium-web-bold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.titillium-web-extralight-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.titillium-web-light-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.titillium-web-regular-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.titillium-web-semibold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.titillium-web-bold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.titillium-web-black {
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  font-style: normal;
}

html {
  background-color: "#FFF";
  font-family: "Titillium Web" !important;
}

.MuiTypography-root,
.MuiButton-root {
  font-family: "Titillium Web" !important;
}

.nav-bar {
  background-color: rgb(12, 11, 11);
}

.nav-button {
  color: white;
}

.nav-bar .MuiButton-root.button-with-outline {
  color: rgb(219, 219, 219);
  border: 1px solid transparent;
  transition: border-color 0.4s ease;
}

.nav-bar .MuiButton-root.button-with-outline:hover {
  border-color: rgb(219, 219, 219);
}

.icon-container {
  margin-right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 100%;
  box-shadow: 0px 0px 10px rgba(255, 219, 88, 0.5);
}

.social-media-button {
  border: 5px solid #000000;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  transition: transform 0.3s ease;
}

.social-media-button:hover {
  transform: scale(1.1);
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate {
  opacity: 1;
  transform: translateY(0);
  animation: slideUp 0.5s ease-out;
}
